;(function (global) {
	global.dateDropperSetup.languages['ar'] = {
		name: '\u0627\u0644\u0639\u064e\u0631\u064e\u0628\u0650\u064a\u064e\u0651\u0629',
		m: {
			s: [
				'\u062c\u0627\u0646\u0641\u064a',
				'\u0641\u064a\u0641\u0631\u064a',
				'\u0645\u0627\u0631\u0633',
				'\u0623\u0641\u0631\u064a\u0644',
				'\u0645\u0627\u064a',
				'\u062c\u0648\u0627\u0646',
				'\u062c\u0648\u064a\u0644\u064a\u0629',
				'\u0623\u0648\u062a',
				'\u0633\u0628\u062a\u0645\u0628\u0631',
				'\u0623\u0643\u062a\u0648\u0628\u0631',
				'\u0646\u0648\u0641\u0645\u0628\u0631',
				'\u062f\u064a\u0633\u0645\u0628\u0631',
			],
			f: [
				'\u062c\u0627\u0646\u0641\u064a',
				'\u0641\u064a\u0641\u0631\u064a',
				'\u0645\u0627\u0631\u0633',
				'\u0623\u0641\u0631\u064a\u0644',
				'\u0645\u0627\u064a',
				'\u062c\u0648\u0627\u0646',
				'\u062c\u0648\u064a\u0644\u064a\u0629',
				'\u0623\u0648\u062a',
				'\u0633\u0628\u062a\u0645\u0628\u0631',
				'\u0623\u0643\u062a\u0648\u0628\u0631',
				'\u0646\u0648\u0641\u0645\u0628\u0631',
				'\u062f\u064a\u0633\u0645\u0628\u0631',
			],
		},
		w: {
			s: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
			f: [
				'\u0627\u0644\u0623\u062d\u062f',
				'\u0627\u0644\u0625\u062b\u0646\u064a\u0646',
				'\u0627\u0644\u062b\u0644\u062b\u0627\u0621',
				'\u0627\u0644\u0623\u0631\u0628\u0639\u0627\u0621',
				'\u0627\u0644\u062e\u0645\u064a\u0633',
				'\u0627\u0644\u062c\u0645\u0639\u0629',
				'\u0627\u0644\u0633\u0628\u062a',
			],
		},
	}
})((globalThis.CodeCrumbs = globalThis.CodeCrumbs || {}))
